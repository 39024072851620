:root {
    /*************THEME COLORS**************/

    --primary-col: #41c752;
    --primary-col1: #8ddd97;
    --primary-col2: #b3e9ba;
    --primary-col3: #d9f4dc;
    --primary-col-hover: #67d275;
    --text-dark: #383b42;
    --text-dark2: #87898d;
    --text-light: #fff;
    --color-success: #2de786;
    --color-neutral: #aaaaaa;
    --color-danger: #ffa500;
    --color-blue: #283551;
}
a {
    text-decoration: none;
}
.img-responsive {
    display: block;
    margin: 0 auto;
}
.banner-img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
.card-holder {
    margin-bottom: 50px;
}
.card-holder svg {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
.text-ellipse {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 40px;
    margin: 0 auto;
    font-size: 26px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-left {
    text-align: left;
}
.custom-card {
    box-shadow: none;
    border: 1px solid #eee;
}
.custom-text {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 22px;
    margin: 0 auto;
    font-size: 15px;
    line-height: 1.4;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-padd {
    padding-top: 0 !important;
}
.custom-label {
    position: relative;
    top: -6px;
}
.slider-img {
    position: absolute !important;
    width: 100%;
    height: 388px;
    object-fit: cover !important;
    object-position: center center !important;
}
.img-res svg {
    display: block;
    margin: 0 auto;
}

.custom-margin-bottom {
    margin-bottom: 50px;
}
.custom-margin-top {
    margin-top: 50px;
}
/* .banner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
} */
.banner-star {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 3px;
}
.custom-paddz {
    padding: 50px 0;
}
.text-adj {
    height: 150px;
    -webkit-line-clamp: 7;
}

/* FLIP */
.flip-card {
    background-color: transparent;
    /* width: 300px; */
    height: 334px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-trigger {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #fff; */
    /* color: black; */
}

.flip-card-back {
    /* background-color: #fff; */
    /* color: white; */
    transform: rotateY(180deg);
}

/************SMALL ICONS*************/
.client-small-icon svg .icon-solid-1 {
    fill: var(--primary-col1) !important;
}
.client-small-icon svg .icon-solid-2 {
    fill: var(--primary-col2) !important;
}
.client-small-icon svg .icon-solid-2-stroke {
    stroke: var(--primary-col2) !important;
}
.client-small-icon svg .icon-solid-3 {
    fill: var(--primary-col3) !important;
}
.client-small-icon svg .icon-solid-4 {
    fill: var(--primary-col) !important;
}
.client-small-icon svg .icon-solid-hover {
    fill: var(--primary-col-hover) !important;
}
.client-small-icon svg .icon-white {
    fill: var(--white) !important;
}
.client-small-icon svg .icon-solid-4-stroke {
    stroke: var(--primary-col) !important;
}
.client-small-icon svg .icon-solid-red {
    fill: var(--red) !important;
}
.custom-pos {
    position: relative;
    top: -8px;
}
.custom-padz {
    margin-bottom: 10px !important;
}
.custom-justify {
    vertical-align: middle;
    display: inline-block;
}
.grid-override {
    width: var(--ion-grid-width-xl, var(--ion-grid-width, 1840px));
}
.custom-track {
    position: relative;
    background: #eee;
    z-index: 1;
    height: 4px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
}
.custom-bar {
    position: absolute;
    width: 0%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #41c752;
    transition: width 2s;
}
.text-fixed {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bg-wrapper {
    background-color: #d8e7ee;
}
ion-content,
.searchbar-input {
    background: #fff !important;
}
body.dark th,
body.dark td {
    color: #fff;
}
body.dark .modal-content {
    background-color: #1e1e1e;
    border-left: 1px solid #aaa;
}
body.dark .modal-header {
    border-bottom: 1px solid #aaa;
}
body.dark .modal-footer {
    border-top: 1px solid #aaa;
}
body.dark .form-control,
body.dark .modal-content input,
body.dark .modal-content textarea,
body.dark .modal-content .css-1s2u09g-control {
    background-color: #363636;
    border: 1px solid #4d4d4d;
    color: #595959;
}
body.dark .modal-content .css-1rhbuit-multiValue {
    background-color: #4d4d4d;
}
body.dark .modal-content .css-1rhbuit-multiValue .css-12jo7m5 {
    color: #aaa;
}
body.dark .custom-control-label::before {
    background-color: #363636;
}
.col-box {
    width: 36px;
    height: 36px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 10px;
    display: block;
    background: #fff;
    cursor: pointer;
    margin-left: 8px;
}
.label-sub {
    font-size: 12px;
}
.pop-holder {
    position: relative;
}
.pop-picker {
    position: absolute;
    z-index: 3;
    top: 40px;
}
.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2;
}
.logo-preview {
    width: 100%;
    height: 288px;
    background-color: #f4f4f4;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}
.logo-position {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}
/*************CUSTOMER SUPPORT PAGE*************/
.color-dark {
    color: var(--text-dark);
}
.color-dark2 {
    color: var(--text-dark2);
}
.color-light {
    color: var(--text-light);
}
.color-success {
    color: var(--color-success);
}
.color-neutral {
    color: var(--color-neutral);
}
.color-danger {
    color: var(--color-danger);
}
.color-blue {
    color: var(--color-blue);
}
.bg-success {
    background: var(--color-success);
}
.bg-neutral {
    background: var(--color-neutral);
}
.bg-danger {
    background: var(--color-danger);
}
.fz-10 {
    font-size: 10px;
}
.fz-12 {
    font-size: 12px;
}
.fz-14 {
    font-size: 14px;
}
.fz-16 {
    font-size: 16px;
}
.fz-18 {
    font-size: 18px;
}
.fz-20 {
    font-size: 20px;
}
.fz-22 {
    font-size: 22px;
}
.fz-32 {
    font-size: 32px;
}
.font-bold {
    font-weight: bold;
}
.app-body {
    background: #f7fafc;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control {
    height: auto;
    padding: 6.5px 14px;

    font-size: inherit;
    color: #777777 !important;
    border-color: #ededed !important;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 5%);
}
/* .form-control:hover {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 5%);
} */
.form-group {
    margin-bottom: 15px;
}
.form-group:focus-visible,
.form-control:focus-visible {
    outline: none;
}
.PhoneInput {
    background: #fff;
    height: auto;
    padding: 6.5px 14px;
    font-size: inherit;
    color: #777777 !important;
    border-color: #ededed !important;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 5%);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.PhoneInputInput,
.PhoneInputInput:focus-visible {
    border: none;
    outline: none;
}
.spacer {
    width: 72px;
    height: 32px;
    opacity: 0;
}
.alert {
    background-color: #fff;
    box-shadow: 0 3px 13px rgb(0 0 0 / 6%);
    border-radius: 4px;
    border: none;
    padding: 23px 26px 17px;
    margin-bottom: 2rem;
    position: relative;
}
.forms-holder {
    background-color: #fff;
    box-shadow: 0 3px 13px rgb(0 0 0 / 6%);
    border-radius: 4px;
    border: none;
    padding: 23px 80px 17px;
    position: relative;
}
.form-label {
    color: var(--text-dark);
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}
.form-border {
    background: #ccc;
}
.account-title {
    margin: 0;
}
.accout-p {
    margin: 0;
    margin-bottom: 25px;
}
.banner-title {
    margin: 0;
}
.banner-p {
    margin: 0;
    margin-top: 5px;
}
.row-header {
    margin-bottom: 15px;
}
.disabled-input {
    box-shadow: none;
    border: 1px solid transparent;
    border-color: transparent !important;
    background-color: #fff !important;
    /* padding: 6.5px 0; */
}
.form-phone-holder {
    position: relative;
}
.form-phone-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.form-alert {
    margin-top: 1rem;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 100%;
}
.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
@media only screen and (max-width: 480px) {
    .banner-star-content {
        display: block;
        text-align: center;
    }
    .banner-content {
        background-position: 58% 50% !important;
    }

    .forms-holder {
        padding: 23px 20px 17px;
    }
    .account-title {
        font-size: 22px !important;
    }
    .forms-holder h2 {
        font-size: 18px !important;
    }
}
